<template>
  <div class="wrapper" v-if="details.pic">
    <van-swipe class="swiper" @change="onChange">
      <van-swipe-item v-for="(item, index) in details.pic" :key="index">
        <img :src="item" alt="">
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/{{details.pic.length}}</div>
      </template>
    </van-swipe>
    <div class="activity-time flex ai-c jc-sb">
      <div class="original-price flex ai-c">
          <div class="lb">原价</div>
          <div class="txt">¥{{details.priceLining.toFixed(2)}}</div>
      </div>
      <div class="price">{{details.price.toFixed(2)}}</div>
      <div class="activity-r flex fd-c ai-fd">
        <div class="t1">距活动结束还有</div>
        <div class="flex ai-c">
          <div class="nb">{{details.date.day}}</div>
                  <div class="txt">天</div>
                  <div class="nb">{{details.date.hour}}</div>
                  <div class="txt">:</div>
                  <div class="nb">{{details.date.minute}}</div>
                  <div class="txt">:</div>
                  <div class="nb">{{details.date.second}}</div>
        </div>
      </div>
      <div class="sold">已售{{details.sale}}{{details.unit}}</div>
    </div>
    <div class="title-box">
        <div class="flex jc-sb">
          <div class="title">
            {{details.name}}
          </div>
          <div class="share flex ai-c jc-c">
            <img src="/img/share.png" alt="">
          </div>
        </div>
        <div class="invitation-box flex ai-c jc-sb">
            <div class="">
                <div class="invitation-t">
                    已有<font>192</font>人跟团，剩余<font>100</font>份
                </div>
                <div class="invitation-user-img">
                    <img v-for="(item, index) in 6" :key="index" src="/img/portrait/1.png" alt="">
                </div>
            </div>
            <div class="btn">邀请好友一起团</div>
        </div>
      </div>
      <div class="nr">
        <div class="title-k">商品详情</div>
        <div class="fwb" v-html="details.detailHtml"></div>
      </div>
      <div class="goods-box">
          <div class="title-k">团品选择</div>
          <div class="goods-list flex fd-c ai-c">
                <div class="goods-item flex" v-for="(item, index) in 3" :key="index">
                    <img src="/img/goods.png" alt="">
                    <div class="">
                        <div class="goods-name lines">巴西ACAINOW巴西莓喷干粉冻 干粉70G</div>
                        <div class="goods-t">卖家包邮</div>
                        <div class="money-box flex ai-c jc-sb">
                            <div class="money">¥149</div>
                            <div class="btn">跟团购买</div>
                        </div>
                    </div>
                    <div class="nb">已团100</div>
                </div>
          </div>
          
      </div>
      <div class="record-box">
          <div class="title-k">跟团记录</div>
          <div class="record-list">
              <div class="record-item flex ai-c jc-sb" v-for="(item, index) in 10" :key="index">
                  <div class="t t1">{{23-index}}</div>
                  <img src="/img/portrait/1.png" alt="">
                  <div class="t t2">恩**</div>
                  <div class="t t3">1分钟前</div>
                  <div class="t line t4">巴西ACAINOW巴西巴西巴西巴西</div>
                  <div class="t t5">+1</div>
              </div>
          </div>
          <div class="see-more">查看更多></div>
      </div>
      <div class="kong"></div>
      <div class="btn-box flex">
        <div class="btn1 flex fd-c ai-c jc-c" @click="$router.push('/home')">
          <img src="/img/icon-home1.png" alt="">
          首页
        </div>
        <div class="btn1 flex fd-c ai-c jc-c">
          <img src="/img/icon-kefu.png" alt="">
          客服
        </div>
        <div class="btn2" @click="btnClick">跟团购买</div>
      </div>
      <template v-if="skuData">
        <v-sku ref="sku" :skuData="skuData" @skuBack="skuBack"  />
      </template>
  </div>
</template>

<script>
import { marketDetails } from '../../../api'
import sku from '@/components/sku'
export default {
  components: {
    'v-sku': sku
  },
  props: {},
  data() {
    return {
      current: 0,
      details: {},
      skuData: {},
      collect: false,
      sku: [],
      timer: null
    };
  },
  watch: {},
  computed: {},
  methods: {
    onChange(index) {
      this.current = index;
    },
    btnClick(type){
      this.$refs.sku.show = true
    },
    marketDetails() {
      marketDetails(this.$route.query.id).then(res=>{
        var goods = res.data.data.goods
        goods.date = this.countDownTime2(res.data.data.endTime.replace(/-/g, '/'),'day',this.currentTime())
        this.timer = setInterval(()=>{
          goods.date = this.countDownTime2(res.data.data.endTime.replace(/-/g, '/'),'day',this.currentTime())
        },1000)
        this.collect = res.data.data.collect
        this.sku = res.data.data.sku
        goods.pic = goods.pic?goods.pic.split(','):[]
        this.details = goods
        var tree = []
        var specs = res.data.data.specs
        var obj = {}
        
        for (var item of specs) {
          if (!obj[item.kid]) {
            obj[item.kid] = {
              k: item.k,
              k_s: 's'+item.kid,
              v: [],
            }
          }
          if (item.sort==0) {
            obj[item.kid].v.push({
              id: item.vid,
              name: item.v,
              imgUrl: item.pic?item.pic:goods.pic[0],
              previewImgUrl: item.pic?item.pic:goods.pic[0],
            })
          } else {
            obj[item.kid].v.push({
              id: item.vid,
              name: item.v,
            })
          }
        }
        
        for (var key in obj) {
          tree.push(obj[key])
        }
        var list = []
        var sku = res.data.data.sku
        for (var item of sku) {
          item.spData = JSON.parse(item.spData)
          var o = {
            id: item.id,
            stock_num: item.stock,
            price: this.NumberMul(item.price, 100),
          }
          for (var im of item.spData) {
            o['s'+im.k_id] = im.v_id
          }
          list.push(o)
        }
        var goods = goods
        goods.picture = goods.pic[0]
        var skuData = {
          tree: tree,
          list: list,
          price: goods.price.toFixed(2),
          stock_num: goods.stock,
          none_sku: false, // 是否无规格商品
          hide_stock: false, // 是否隐藏剩余库存
          goods: goods
        }
        this.skuData = skuData
      })
    },
    //加入购物车或者立即购买回调
    skuBack (e) {
      //加入购物车1
      if (e.type=='1') {
        
      } else {
        localStorage.removeItem('orderInfo')
        var pic = ''
        var specifications = []
        for (var item of this.sku) {
          if (item.id == e.selectedSkuComb.id) {
            pic = item.pic?item.pic:this.details.pic[0]
            for (var im of item.spData) {
              specifications.push(im.v)
            } 
            break
          }
        }
        var orderInfo = {
          couponId: null,
          note: null,
          source: 0,
          userAddressId: null,
          name: null,
          phone: null,
          address: null,
          item: [{
            cartId: null,
            quantity: e.selectedNum,
            skuId: e.selectedSkuComb.id,
            price: this.NumberDiv(e.selectedSkuComb.price,100,2),
            name: this.details.name,
            pic: pic,
            specifications: specifications.join('、')
          }]
        }
        localStorage.setItem('orderInfo', JSON.stringify(orderInfo))
        this.$router.push({path: '/home/orderConfirm'})
      }
    },
    currentTime() {
        function nb(data){
            return data<10?'0'+data:data
        }
        var now = new Date();
        var year = now.getFullYear(); //得到年份
        var month = now.getMonth()+1;//得到月份
        var date = now.getDate();//得到日期
        // var day = now.getDay();//得到周几
        var hour= now.getHours();//得到小时数
        var minute= now.getMinutes();//得到分钟数
        var second= now.getSeconds();//得到秒数
        return year + '/' + nb(month) + '/' + nb(date) + ' ' + nb(hour) + ':' + nb(minute) + ':' + nb(second)
      },
      countDownTime2(endTime, maxUnit = "day", startTime) {
            let end = new Date(endTime);
            let start = startTime ? new Date(startTime) : new Date();
            if (start - end > 0) {
                // throw new Error("开始时间不能晚于结束时间")
                return {
                    day: 0,
                    hour: 0,
                    minute: 0,
                    month: 0,
                    second: 0,
                    year: 0
                }
            }
            let aUnitArr = [
                {
                    value: "second",
                    interval: 60,
                    secNum: 1 //该单位有多少秒，计算该单位最大差值用到
                },
                {
                    value: "minute",
                    interval: 60,
                    secNum: 60
                },
                {
                    value: "hour",
                    interval: 24,
                    secNum: 60 * 60
                },
                {
                    value: "day",
                    secNum: 60 * 60 * 24
                },
                {
                    value: "month",
                    interval: 12
                },
                {
                    value: "year",
                },
            ]
            let endList = this.getTimeList(end);
            let startList = this.getTimeList(start);
            const iMaxIndex = aUnitArr.findIndex(item => maxUnit === item.value);
            // 当最大单位为日时分秒时过滤。月份最大单位需根据年份反算所以不能过滤掉年份
            if (iMaxIndex > -1 && iMaxIndex < 4) {
                aUnitArr = aUnitArr.filter((item, index) => iMaxIndex > -1 && index <= iMaxIndex);
            }
            let result = {};
            aUnitArr.forEach((item, index) => {
                if (index === iMaxIndex && iMaxIndex < 4) {
                    result[item.value] = Math.floor((end - start) / item.secNum / 1000);
                    return
                }
                if (endList[index] - startList[index] >= 0) {
                    result[item.value] = endList[index] - startList[index];
                } else {
                    endList[index + 1]--;
                    result[item.value] = item.value === "day" ? 
                    	this.countDiffDays(start, startList[index], endList[index]) : endList[index] + item.interval - startList[index];
                }
            })
            // 最大单位是月份时特殊处理
            if (maxUnit === "month") {
                result.month += result.year * 12
                delete result.year
            }
            return result;
        },
         getTimeList(t) {
            return [t.getSeconds(), t.getMinutes(), t.getHours(), t.getDate(), t.getMonth() + 1, t.getFullYear()];
        },
        countDiffDays(time, startDay, endDay) {
            let curDate = new Date(time);
            let curMonth = curDate.getMonth();
            /* 这里将时间设置为下个月之前，需要把日期设置小一点，否则极端情况，如果当天日期大于下一个月的总天数，月份会设置为下下个月 */
            curDate.setDate(1)
            curDate.setMonth(curMonth + 1);
            curDate.setDate(0);//日期设置为前一个月的最后一天
            let restDays = curDate.getDate() - startDay;
            return restDays + endDay;
        }
  },
  created() {
    this.marketDetails()
  },
  mounted() {},
  beforeDestroy() { // 在组件生命周期结束的时候销毁。
    clearInterval(this.timer)
  }
};
</script>
<style scoped>
.wrapper .swiper{
  position: relative;
  width: 100%;
  height: 8.78rem;
}
.wrapper .swiper img{
  display: block;
  width: 100%;
  height: 8.78rem;
}
.custom-indicator {
  position: absolute;
  right: .3rem;
  bottom: .3rem;
  padding: .02rem .05rem;
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #3C3C3C;
  background: #FFFFFF;
  border-radius: .02rem;
}
.activity-time{
  position: relative;
  width: 100%;
  height: 2.3rem;
  background-image: url(/img/pingtuan.png);
  background-size: 100% 100%;
}
.original-price{
    position: absolute;
    top: .15rem;
    left: 1.68rem;
}
.original-price .lb{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    margin-right: .15rem;
}
.original-price .txt{
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    text-decoration: line-through;
}
.price{
    position: absolute;
    left: .78rem;
    top: 1.2rem;
    font-size: .56rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FF4C2B;
}
.price::before{
    content: '¥';
    font-size: .34rem;
    font-weight: normal;
    margin-right: .1rem;
}
.price::after{
    content: '起';
    font-size: .26rem;
    font-weight: normal;
}
.sold{
    position: absolute;
    right: .48rem;
    top: 1.55rem;
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FF4C2B;
}
.activity-r{
  position: absolute;
  right: .3rem;
  top: .1rem;
}
.activity-r .t1{
  font-size: .26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
}
.activity-r .txt{
  font-size: .26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
}
.activity-r .nb{
  width: .40rem;
  height: .46rem;
  text-align: center;
  line-height: .46rem;
  background: #FFFFFF;
  border-radius: .10rem;
  margin: 0 .05rem;
  font-size: .26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #F9591B;
}
.title-box {
  background-color: #fff;
  overflow: hidden;
}
.title-box .title{
  width: 5.5rem;
  font-size: .32rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-left: .28rem;
  margin-top: .3rem;
}
.title-box .share{
  width: 1rem;
  height: .9rem;
  border-left: 1px solid #e7e7e7;
  margin-top: .3rem;
}
.title-box .share img{
  width: .43rem;
}
.invitation-box{
    width: 6.6rem;
    margin-left: .45rem;
    height: 1.34rem;
    background: #FFF1ED;
    border-radius: .10rem;
    margin-top: .6rem;
    margin-bottom: .44rem;
    overflow: hidden;
}
.invitation-box .invitation-t{
    font-size: .26rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
    margin-left: .1rem;
}
.invitation-box .invitation-t font{
    color: rgba(255, 76, 43, 1);
}
.invitation-user-img{
    margin-left: .1rem;
    margin-top: .1rem;
}
.invitation-user-img img{
    width: .58rem;
    height: .58rem;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    margin-left: -.06rem;
}
.invitation-user-img img:nth-child(1){
    margin-left: 0;
}
.invitation-box .btn{
    width: 2.46rem;
    height: .60rem;
    line-height: .6rem;
    text-align: center;
    background: #F96F30;
    border-radius: .30rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    margin-right: .15rem;
}
.nr{
    width: 100%;
    margin-top: .2rem;
    background-color: #fff;
    overflow: hidden;
}
.title-k{
    position: relative;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: bold;
    color: #353535;
    margin-left: .7rem;
    margin-top: .37rem;
}
.title-k::before{
    content: '';
    position: absolute;
    top: calc(50% - .18rem);
    left: -.24rem;
    width: .06rem;
    height: .36rem;
    background: linear-gradient(0deg, #FACAB6 0%, #F24926 100%);
    border-radius: .02rem;
}
.fwb{

}
.btn-box{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 7.50rem;
  height: 1rem;
  background: #FFFFFF;
  border-top: 1px solid rgba(178, 178, 178, 0.6);
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.btn-box .btn1{
  width: 1.8rem;
  height: 100%;
  font-size: .22rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626A;
}
.btn-box .btn1 img{
  height: .36rem;
  margin-top: .1rem;
}
.btn-box .btn1:nth-child(1){
  border-right: 1px solid rgba(178, 178, 178, 0.6);
}
.btn-box .btn2{
  flex: 1;
  height: 100%;
  background: #F35E3A;
  font-size: .36rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
  font-size: .36rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  line-height: 1rem;
}
.goods-box{
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    margin-top: .4rem;
}
.goods-list{
    margin-top: .1rem;
    overflow: hidden;
}
.goods-item{
    position: relative;
    width: 6.6rem;
    padding: .35rem 0;
    border-bottom: 0.02rem solid #EDEDED;
}
.goods-item img{
    width: 2.02rem;
    height: 2.02rem;
    border-radius: .10rem;
    margin-right: .28rem;
}
.goods-name{
    height: .72rem;
    font-size: .30rem;
    line-height: .36rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
}
.goods-t{
    margin-top: .05rem;
    font-size: .26rem;
    font-family: PingFang;
    font-weight: 500;
    color: #6E6E6E;
}
.money-box{
    margin-top: .2rem;
}
.money-box .money{
    font-size: .30rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FF4C2B;
}
.money-box .btn{
    width: 1.98rem;
    height: .60rem;
    background: #F96F30;
    border-radius: .30rem;
    text-align: center;
    line-height: .6rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
}
.goods-item .nb{
    position: absolute;
    bottom: 1.15rem;
    right: 0;
    font-size: .26rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FF4C2B;
}
.record-box{
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    margin-top: .25rem;
}
.record-box .record-list{
    padding-top: .4rem;
    padding-bottom: .2rem;
}
.record-item{
    width: 100%;
    box-sizing: border-box;
    padding: 0 .45rem;
    margin-bottom: .2rem;
}

.record-box .record-item .t{
    font-size: .26rem;
    font-family: PingFang;
    font-weight: 500;
    color: #505050;
}
.record-box .record-item .t1{
    width: .5rem;
}
.record-box .record-item .t2{
    width: .7rem;
    margin-right: .3rem;
}
.record-box .record-item .t3{
    width: 1.3rem;
    margin-right: .2rem;
}
.record-box .record-item .t4{
    width: 2.5rem;
    margin-right: .2rem;
}
.record-box .record-item .t5{
    color: #FF3604;
}
.record-box .record-item img{
    width: .42rem;
    height: .42rem;
    border-radius: .04rem;
    margin: 0 .2rem 0 .1rem;
}
.record-box .see-more{
    width: 7.10rem;
    height: .95rem;
    text-align: center;
    line-height: .95rem;
    margin-left: .2rem;
    border-top: 1px solid #EDEDED;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}
.kong {
    width: 100%;
    height: 2rem;
}
</style>